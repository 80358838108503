// mixins for clearfix
// ------------------------
.clearfix() {
  &::before,
  &::after {
    display: table;
    content: '';
  }
  &::after {
    clear: both;
  }
}
